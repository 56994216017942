<template>
  <div>
    <default-app-bar :showCancel="false">
      <template v-slot:menu>
        <v-app-bar-nav-icon @click.stop="toogleDrawer" color="primary"></v-app-bar-nav-icon>
      </template>
      <template v-slot:title>
        <v-toolbar-title class="px-0">
          <img src="/assets/images/brand/primary-icon-logo.svg" class="d-sm-block d-lg-none" width="40" />
          <img src="/assets/images/brand/primary-horizontal-logo.svg" class="d-none d-lg-block" width="180" />
        </v-toolbar-title>
      </template>

      <template v-slot:actions>
        <v-row>
          <v-col cols="12" class="d-flex flex-row align-center">
            <div class="d-flex flex-row align-center" v-if="!$store.getters.mobile">
              <v-btn text small class="white--text me-2">{{
                $t("price_list")
              }}</v-btn>
              <ng-select-options class="price_list" v-if="$pos && $pos.priceLists" v-model="currentPriceList"
                :items="$pos.priceLists" @change="changePriceList" :clearable="false" :flat="false" :filled="false" dense
                :outlined="true" hide-details :full-width="false"></ng-select-options>
            </div>

            <div class="retail-btn">
              <v-btn text small color="white" class="ml-1 me-2"
                v-if="!$store.getters.isRestaurant && !$store.getters.mobile">
                {{ $t("retail") }}
              </v-btn>
            </div>

            <div class="d-flex flex-row white rounded-pill py-1 px-2 me-2">
              <v-btn fab small color="orange lighten-5" class="me-2" :title="$t('pending_orders')"
                @click="openModalCartPendingOrders()">
                <v-badge color="orange darken-2" :content="'' + totalPendingOrders" overlap
                  :key="$store.getters.orderChanged">
                  <v-icon color="orange darken-2">mdi-receipt</v-icon>
                </v-badge>
              </v-btn>
              <v-btn fab small color="green lighten-5" class="me-2" :title="$t('pending_orders')">
                <v-badge color="green darken-2" :content="Math.ceil($pos.getCurrentOrder().getTotalItems())" overlap
                  :key="$store.getters.orderChanged">
                  <v-icon color="green darken-1">mdi-basket</v-icon>
                </v-badge>
              </v-btn>
              <v-btn fab small color="blue lighten-5" @click="updateInfo()" :title="$t('syncro')"><v-icon
                  color="blue">mdi-cloud-sync-outline</v-icon>
              </v-btn>
            </div>
            
            <!---User -->
            <v-menu bottom left offset-y origin="top right" transition="scale-transition" class="account-menu">
              <template v-slot:activator="{ on }">
                <v-btn dark icon v-on="on" class="ml-1" id="btn-menu-profile">
                  <v-avatar size="50" class="white">
                    <v-icon color="primary">mdi-account-outline</v-icon>
                  </v-avatar>
                </v-btn>
              </template>

              <v-list style="width: 350px !important">
                <!-- 
                <v-list-item v-if="$store.getters.user" class="d-flex justify-center align-center">

                  <div class="account-header text-center">

                    <v-list-item two-line class="py-3 mx-auto">

                      <div class="acount-data">
                        <v-list-item-avatar v-if="$store.getters.company.logo" class="mx-auto" size="60">

                          <img :src="$http.getBaseUrlPublicFiles() + $store.getters.company.logo"
                            :alt="$store.getters.company.name" />
                        </v-list-item-avatar>

                        <v-list-item-content class="secondary--text pa-0" id="info-company">
                          <v-list dense class="pa-0">
                            <v-list-item v-if="$store.getters.company" class="pa-0 ma-0 primary--text text-center">
                              <v-list-item-content v-if="$store.getters.company">
                                <v-list-item-title v-text="$store.getters.company.name"></v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>

                            <v-list-item v-if="$store.getters.user" class="pa-0 ma-0">
                              <v-list-item-content>
                                <v-list-item-title class="text-center grey--text" v-if="$store.getters.user"
                                  v-text="$store.getters.user.name" />
                                <v-list-item-subtitle class="text-center grey--text"
                                  v-if="$store.getters.user.rol && $store.getters.user.rol.name"
                                  v-text="$store.getters.user.rol.name"></v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>

                            <v-list-item v-if="app_version">
                              <v-list-item-content>
                                <v-list-item-title class="grey--text text--lighten-1 font-weight-light">
                                  v{{ app_version }}
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>

                          </v-list>
                        </v-list-item-content>
                      </div>
                    </v-list-item>
                  </div>
                </v-list-item> 
                <v-divider></v-divider> -->
                <v-list-item class="menu-item-config-settings" @click="openModalCartPendingOrders()">
                  <v-list-item-icon class="orange lighten-5 py-2 px-2 rounded">
                    <v-badge color="orange darken-2" :content="'' + totalPendingOrders" overlap
                      :key="$store.getters.orderChanged">
                      <v-icon color="orange darken-1">mdi-receipt</v-icon>
                    </v-badge>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="orange--text">
                      {{ $t("pending_orders") }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ $t("pending_desc") }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item class="menu-item-config-settings" to="/pos">
                  <v-list-item-icon class="green lighten-5 py-2 px-2 rounded">
                    <v-badge color="green darken-2" :content="Math.ceil($pos.getCurrentOrder().getTotalItems())
                      " overlap :key="$store.getters.orderChanged">
                      <v-icon color="green darken-1">mdi-basket</v-icon>
                    </v-badge>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="green--text">
                      {{ $t("products") }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ $t("products_desc") }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item class="menu-item-config-settings" @click="updateInfo()">
                  <v-list-item-icon class="primary py-2 px-2 rounded">
                    <v-icon color="white">mdi-cloud-sync-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="primary--text">
                      {{ $t("syncro") }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ $t("syncro_desc") }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item id="menu-item-logout" class="text-center grey--text">
                  <v-btn @click="$store.dispatch('logoutAuth')" elevation="0" block outlined color="primary"
                    class="rounded-lg">{{ $t("logout") }}</v-btn>
                </v-list-item>
              </v-list>
            </v-menu>
            <!---User -->
          </v-col>
        </v-row>
        <!--a v-if="$store.getters.mobile" @click="dialogFilter = true"><v-icon>mdi-magnify</v-icon></a-->
      </template>
    </default-app-bar>

    <!-- m="4" md="4" lg="4" --> 
    <v-row v-if="installed" tabindex="0" class="ma-0 pa-0">
      
      <template v-if="$store.getters.mobile">
        <v-col cols="12" v-if="!showCartMobile" class="pa-0 mb-0">
          <v-toolbar dark color="info" dense class="fixed-bar">
            <v-toolbar-title class="text-sm-caption text-md-h5">{{ $t("total") }}
              {{
                $filters.currency(
                  $pos.getCurrentOrder().getPriceTotalWithAdds(),
                  0
                )
              }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-title>{{ $t("view_order") }}</v-toolbar-title>
            <v-btn v-if="$store.getters.mobile" small fab color="grey lighten-5" @click="viewCartMobile(true)" class="ml-2">
              <v-icon color="primary">mdi-arrow-right</v-icon>
            </v-btn>
          </v-toolbar>
        </v-col>

        <v-col cols="12" v-if="showCartMobile" class="pa-0">
          <v-toolbar dark color="blue" dense class="fixed-bar">
            <v-btn fab small color="white" @click="viewCartMobile(false)" class="me-2">
              <v-icon color="primary">mdi-arrow-left</v-icon>
            </v-btn>
            <v-toolbar-title>{{ $t("go_back_products") }}</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
        </v-col>
      </template>

      <SearchProducts @select:product="manageProductSelected" @select:product-variant="manageProductVariantSelected" v-if="!showCartMobile"
        :keyReload="keyReloadProducts" :is_mobile="$store.getters.mobile" :aspect_ratio="$store.getters.aspect_ratio"
        :priceList="currentPriceList" ref="searchProd"></SearchProducts>
      
      <CartSection :order="currentOrder" @edit:item="editItemLine" :key="cartKey" @created:order="viewCartMobile(false)" 
     
        :is_mobile="$store.getters.mobile" :aspect_ratio="$store.getters.aspect_ratio"></CartSection>
    </v-row>

    <InstallWaiter :open="openInstall" @cancel="cancelEditProduct()" @installed="installedOk"></InstallWaiter>

    <CartSectionProductDetail :dialog="modalItem" :key="key" @selectedOptions="selectedOptions"
      :currentProduct="currentProduct" :itemLine="currentItemLine" @cancel="closeItemDetail"
      :priceList="currentPriceList">
    </CartSectionProductDetail>

    <CartPendingOrdersRemote :dialog="modalCartPendingOrders" @cancel="modalCartPendingOrders = false"
      ref="modalPendingOrders" @close="modalCartPendingOrders = false"></CartPendingOrdersRemote>

    <v-dialog v-model="showWarningOrderWithoutSave" width="350">
      <v-card>
        <v-card-text class="pt-4 text-center d-flex flex-column overflow-hidden">
          <div class="mb-3">
            <v-icon color="primary" class="blue lighten-5 pa-3 rounded-pill" size="40">mdi-information</v-icon>
          </div>
          Actualmente hay una orden en proceso. Por favor, finaliza o cancela la
          orden antes de proceder con una nueva.
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn @click="showWarningOrderWithoutSave = false" color="primary" outlined block>Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<style>
.v-main__wrap {
  padding-top: 21px !important;
}

@media only screen and (max-width: 981px) {
  .v-main__wrap {
    padding-top: 90px !important;
  }
}

.price_list .v-text-field fieldset,
.price_list .v-text-field .v-input__control,
.price_list .v-text-field--single-line .v-select__selections,
.price_list .v-select__selection,
.price_list .v-icon {
  color: white !important;
}

.fixed-bar {
  position: fixed;
  top: 70px;
  min-height: 70px;
  width: 100%;
  z-index: 2;
  box-shadow: none !important;
}

.cart_mobile {
  margin-top: 45px !important;
}

.account-menu .v-list .v-list-item--active {
  color: transparent !important;
}
</style>
  
<script>
import SearchProducts from "./../pos/components/SearchProducts";
import InstallWaiter from "./InstallWaiter";
import OpenTurn from "./../pos/components/OpenTurn";
import CartSection from "./CartSectionWaiters";
import CartSectionProductDetail from "./../pos/components/CartSectionProductDetail";
import CartPendingOrdersRemote from "./../pos/components/CartPendingOrdersRemote";

export default {
  name: "WaitersIndex",
  components: {
    SearchProducts,
    CartSection,
    CartSectionProductDetail,
    CartPendingOrdersRemote,
    InstallWaiter,
    OpenTurn,
  },
  data: () => ({
    openInstall: false,
    ws: null, //websocket local, funciona sin internet, para validar que nose conecten varias ventanas del pos
    socket: null, //websocket nube, para validar si esta abierta la caja y otras validaciones
    openTurn: false,
    installed: false,
    key: 0,
    cartKey: 0,
    products: [],
    currentPriceList: null,
    priceLists: [],
    currentOrder: null,
    modalItem: false,
    modalCartPendingOrders: false,
    currentProduct: false,
    currentItemLine: false,
    keyReloadProducts: 0,
    databaseLoaded: false,
    totalPendingOrders: 0,
    app_version: null,
    turn: null,
    showWarningOrderWithoutSave: false,
    showCartMobile: false,
    //cart: null
    //priceList:
  }),
  computed: {
    currentTurn() {
      return this.turn;
    },
  },
  watch: {
    "$store.getters.orderChanged": function () {
      this.loadGeneralInfo();
    },
    "$store.getters.online": function () {
      if (this.$store.getters.online) {
        this.$pos.sincronizeSync();
      }
    },
    "$store.state.db.loaded": function () {
      this.databaseLoaded = true;
      console.log("loaded bd.............");
      /* setTimeout(() => {
          this.installedEvent();
        }, 1000);*/
    },
  },
  mounted() {
    setTimeout(async () => {
      this.installedEvent();

    }, 500);
  },
  methods: {
    windowClose() {
      window.close();
    },

    changePriceList() { },
    async testPrint() {
      const template = await this.$db.getTemplate(2);
      this.$store.dispatch("printData", {
        tmpl: template.body,
        info: { name: "" },
      });
    },
    openHelp() {
      window.open(process.env.VUE_APP_BASECONFIG_URL_HELP, "_blank");
    },
    async loadGeneralInfo() {
      const orders_pending = await this.$db.getOrdersByType("pending");
      this.totalPendingOrders = orders_pending.length;
    },
    closeItemDetail() {
      this.modalItem = false;
      this.key++;
    },
    anullCurrentOrderClick() {
      this.showWarningOrderWithoutSave = false;
      //this.anullCurrentOrder = true;
    },
    newOrder() {
      if (this.$pos.getCurrentOrder().isEmpty()) {
        let defaults = { is_on_table: true, from_seller: true };
        this.$pos.createOrder(false, defaults);
      } else {
        //this.anullCurrentOrder = true;
        this.showWarningOrderWithoutSave = true;
      }
    },
    modalOpenTurn() {
      this.openTurn = true;
    },
    launchMenuPos(evt) {

      return false;
    },

    installedOk() {
      setTimeout(() => {
        //
        this.installedEvent();
        // window.location.href = "/pos";
      }, 1);
      //
    },
    async installedEvent() {
      if (
        this.$store.state &&
        this.$store.state.db &&
        this.$store.state.db.loaded
      ) {
        const installed = await this.$db.itsInstalled();
        if (installed) {
          let installation = this.$store.getters.installation;
          if (!installation) {
            installation = await this.$db.adapter.getInstallation();
            this.$store.dispatch("setInstallation", installation);
          }
          this.$pos.priceLists = await this.$db.getPriceLists();
          this.currentPriceList = this.getCurrentList();
          this.installed = true;
          this.openInstall = false;
          this.loadGeneralInfo();
          this.openCloudSocket();
          this.key++;
          this.keyReloadProducts++;
        } else {
          this.openInstall = true;
        }
      }
      //this.load();
    },
    openCloudSocket() { },
    getCurrentList() {
      for (const ls of this.$pos.priceLists) {
        if (ls.is_general == true) {
          return ls;
        }
      }
      return this.$pos.priceLists[0];
    },
    cancelEditProduct() {
      this.currentProduct = null;
      this.modalItem = false;
    },
    //6545353890355
    async manageProductVariantSelected(variant) {
      let product = await this.$db.getProduct(variant.inv_product_id);
      this.$pos.addItem(
        product,
        {
          id: variant.id,
          name: variant.name,
          reference: variant.reference,
          bar_code: variant.bar_code,
        },
        1,
        "",
        null,
        null,
        -1
      );
    },
    focusFieldQuantityScale() {
      setTimeout(() => {
        const element = document.getElementById("quantity-field");
        if (element !== null) {
          element.focus();
          element.select();
        }
      }, 325);
    },
    // adiciona los productos seleccionados, toma siempre la current list
    manageProductSelected(product) {
      this.currentProduct = null;
      this.currentItemLine = null;
      if (product.type == "variant") {
        this.key++;
        this.currentProduct = product;
        this.modalItem = true;
      } else {
        if (product.sale_options || product.is_scale == true) {
          this.key++;
          this.currentProduct = product;
          this.modalItem = true;
          this.focusFieldQuantityScale();
        } else {
          this.$pos.addItem(
            product,
            null,
            null,
            null,
            null,
            null,
            -1,
            this.currentPriceList.id
          );
        }
      }
    },
    editItemLine(itemLine) {
      this.currentProduct = null;
      this.currentItemLine = itemLine;
      this.modalItem = true;
      this.key++;
      if (itemLine.product && itemLine.product.is_scale) {
        this.focusFieldQuantityScale();
      }
    },
    async selectedOptions(options) {
      if (options.options) {
        for (let i in options.options) {
          if (
            options.options[i] &&
            options.options[i].value &&
            Array.isArray(options.options[i].value) &&
            options.options[i].value.length == 0
          ) {
            delete options.options[i];
          }
        }
      }
      if (options.itemLine) {
        const product = await this.$db.getProduct(options.itemLine.product.id);
        await this.$pos.updateItem(
          options.itemLine,
          product,
          options.variant,
          options.quantity,
          options.note,
          options.options,
          options.discount,
          options.new_price,
          options.bill_price_list_id
        );
        this.cartKey++;
      } else {
        this.$pos.addItem(
          this.currentProduct,
          options.variant,
          options.quantity,
          options.note,
          options.options,
          options.discount,
          options.new_price,
          options.bill_price_list_id
        );
      }
      this.modalItem = false;
    },
    toogleDrawer() {
      this.$store.commit("toggle");
    },
    openModalCartPendingOrders() {
      this.modalCartPendingOrders = true;
      this.$refs.modalPendingOrders.getPendingOrders();
    },
    async updateInfo() {
      await this.$pos.sincronizeSync();
      await this.$pos.syncroRemoteToLocal();
      this.$refs.searchProd.updateProducts({}, 20, "name");

      this.keyReloadProducts++;
    },
    viewCart(view) {
      if (this.currentTurn) {
        if (view == "search_products") {
          if (this.$store.getters.mobile) {
            if (!this.showCartMobile) {
              return true;
            } else {
              return false;
            }
          } else {
            return true;
          }
        }
        if (view == "cart") {
          if (this.$store.getters.mobile) {
            if (this.showCartMobile) {
              return true;
            } else {
              return false;
            }
          } else {
            return true;
          }
        }
      } else {
        return false;
      }
    },
    viewCartMobile(value) {
      this.showCartMobile = value;
    },
  },
};
</script>
  