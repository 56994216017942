<template>
  <v-col md="5" :lg="setColAspect()" class="ma-0 pa-0 grey lighten-5 d-flex flex-column"
    :class="is_mobile ? 'cart_mobile mobile-height' : 'desktop-height'">
    <div class="text-center"><div class="text-h6"  v-if="$store.getters.mobile">Orden</div> </div>
    <v-row v-if="order" no-gutters style="max-height: 48px;">
      <v-col cols="12" class="grey lighten-4 d-flex " >
        <v-btn class="blue--text font-weight-bold flex-grow-1" elevation="0" large color="blue lighten-5" tile
          @click="noteOrder()" :title="$t('add_note')">
          <v-icon left :color="setColorNote()">mdi-comment-text-outline</v-icon>
          {{ $t("note") }}
        </v-btn>
        <v-btn class="flex-grow-1" elevation="0" large tile :title="$t('tables')" @click="modalTables = true"
          v-if="$store.getters.getGeneralValue('gen_sales_manage_tables')">
          <v-icon left>mdi-table-furniture</v-icon>
          {{ $t("tables") }}
        </v-btn>
        <v-btn class="font-weight-bold black--text text--black flex-grow-1" elevation="0" large tile
          v-if="$store.getters.getGeneralValue('gen_sales_activate_coupons')" @click="openModalCoupon()"
          :title="$t('add_coupon')">
          <v-icon left>mdi-tag-multiple-outline</v-icon>
          {{ $t("coupon") }}
        </v-btn>
        <v-btn class="flex-grow-1" elevation="0" large tile :title="$t('print_screen')" @click="printScreen()"
          :disabled="$pos.getCurrentOrder().isEmpty()">
          <v-icon left class="d-none d-lg-block">mdi-printer-outline</v-icon>
          <v-icon class="d-lg-none d-block">mdi-printer-outline</v-icon>
          <span class="d-none d-lg-block">{{ $t("print") }}</span>
        </v-btn>
        <v-btn class="flex-grow-1" elevation="0" large tile :title="$t('print_remote')" @click="printRemote()"
          :disabled="$pos.getCurrentOrder().isEmpty()" v-if="getValueActivePrintRemote()">
          <v-icon>mdi-cloud-print-outline</v-icon>
        </v-btn>
        <v-btn class="red--text flex-grow-1" elevation="0" large tile :disabled="$pos.getCurrentOrder().isEmpty()"
          @click="openAnnulOrder()" :title="$t('cancelar')">
          <v-icon left class="d-none d-lg-block" color="red">mdi-close-circle-outline</v-icon>
          <v-icon class="d-lg-none d-block" color="red">mdi-close-circle-outline</v-icon>
          <span class="d-none d-lg-block">{{ $t("cancel") }}</span>
        </v-btn>
      </v-col>
    </v-row>

    <v-divider />


    <v-row v-if="order" class="mt-0" style="max-height: 260px;" ref="optionsOrder" no-gutters>
      <v-col>
        <v-tabs v-model="tab" background-color="grey lighten-4" grow icons-and-text show-arrows >
          <v-tabs-slider color="grey lighten-2"></v-tabs-slider>

          <v-tab href="#order" class="transparent grey--text">
            Orden
            <v-icon color="grey darken-1">mdi-store</v-icon>
          </v-tab>

          <v-tab href="#tip" class="transparent grey--text">
            Propina
            <v-icon color="grey darken-1">mdi-hand-heart-outline</v-icon>
          </v-tab>

          <v-tab href="#coupon" class="transparent grey--text">
            Cupón
            <v-icon color="grey darken-1">mdi-tag-multiple</v-icon>
          </v-tab>

          <!--v-tab href="#invoice" class="transparent grey--text">
            Factura
            <v-icon color="grey darken-1">mdi-file-sign</v-icon>
          </v-tab-->
        </v-tabs>



        <v-tabs-items v-model="tab">
          <v-tab-item value="order">
            <v-card flat color="grey lighten-5" class="pa-0 ma-0">
              <!--v-card-text class="py-1 d-lg-flex align-center text-center text-lg-left">
                <span class="me-2 font-weight-regular">Tipo de orden:</span>
                <v-btn-toggle mandatory v-model="orderType" @change="changeOrderType" class="mt-lg-0 mt-2">
                  
                  <v-btn class="font-weight-bold black--text text--black" :title="$t('in_table')" value="is_on_table"
                    v-if="$store.getters.getGeneralValue('gen_sales_manage_tables')
                        ">
                    <v-icon>mdi-table-furniture</v-icon>
                    <span>{{ $t("in_table") }}</span>

                  </v-btn>
               
                
                </v-btn-toggle>
              </v-card-text-->
              <v-divider></v-divider>

              <v-card-text class="py-3">
                <v-row>
                  <v-col md="6">
                    <span class="font-weight-regular">{{ $t("client") }}:
                    </span>
                    <v-chip color="blue px-5" dark @click="modalCustomer = true" outlined
                      v-if="!$pos.getCurrentOrder().data_customer" class="font-weight-regular blue--text">
                      <v-icon left>mdi-account-search-outline</v-icon>
                      {{ $t("no_client") }}
                    </v-chip>
                    <v-chip small v-else class="font-weight-regular black--text subtitle-2">
                      {{ $pos.getCurrentOrder().getCustomerName() }}
                      <v-icon @click="modalCustomer = true" right>mdi-account-edit</v-icon>
                      <v-icon color="red" right @click="removeClient()">mdi-close</v-icon>
                    </v-chip>


                


                  </v-col>

                  <v-col md="6">

                    <template>
                  <span class="
                      font-weight-medium
                      black--text
                      text--black
                      subtitle-2
                    ">{{ $t("table") }}:
                  </span>
                  <v-chip outlined color="green px-5" v-if="order.getNameTable()" class="font-weight-regular">
                    {{ order.getNameTable() }}
                    <v-icon right @click="modalTables = true">mdi-pencil</v-icon>
                    <v-icon color="red" right @click="$pos.setNameTable($pos.getCurrentOrder(), null)">mdi-close</v-icon>
                  </v-chip>
                  <v-chip outlined color="blue px-5" @click="modalTables = true" v-else class="font-weight-regular">
                    <v-icon left>mdi-table-furniture</v-icon> {{ $t("no_table") }}
                  </v-chip>
                </template>
                  </v-col>
               
                </v-row>
              </v-card-text>

            </v-card>
          </v-tab-item>

          <v-tab-item value="tip">
            <v-card flat color="grey lighten-5">
              <v-card-text class="d-lg-flex flex-row align-center py-1"><span class="me-2">¿Deseas dejar una propina?
                  (Sí/No)</span>
                <v-switch  class="pa-2 py-0" :title="$t('instructions_pos_tip')"
                  prepend-icon="mdi-cash-100" v-model="activeTip" dense :label="$t('tip')"
                  @change="changeTip()"></v-switch>
              </v-card-text>

            </v-card>
          </v-tab-item>

          <v-tab-item value="coupon">
            <v-card flat color="grey lighten-5">
              <v-card-text class="d-lg-flex flex-row align-center"><span class="me-2">¿Tienes un cupón de descuento para
                  aplicar?</span>
                <div  class="my-auto">
                  <v-chip outlined color="green px-5" v-if="haveCoupon()"
                    class="font-weight-regular black--text subtitle-2">
                    {{ order.getCoupon() }}
                    <v-icon right @click="modalCoupon = true">mdi-tag-multiple-outline</v-icon>
                    <v-icon color="red" right @click="$pos.setCoupon($pos.getCurrentOrder(), null)">mdi-close</v-icon>
                  </v-chip>
                  <template v-else>
                    <v-chip outlined color="orange darken-2 px-5" @click="modalCoupon = true"
                      class="font-weight-regular black--text subtitle-2" v-if="$store.getters.getGeneralValue('gen_sales_activate_coupons')
                        ">
                      {{ $t("no_coupon") }}
                      <v-icon right>mdi-tag-multiple-outline</v-icon>
                    </v-chip>
                    <v-chip outlined color="orange darken-2 px-5" v-else
                      class="font-weight-regular black--text subtitle-2">
                      {{ $t("no_coupon") }}
                      <v-icon right>mdi-tag-multiple-outline</v-icon>
                    </v-chip>
                  </template>
                </div>
              </v-card-text>

            </v-card>
          </v-tab-item>

          <v-tab-item value="invoice">
            <v-card flat color="grey lighten-5">
              <v-card-text class="d-lg-flex flex-row align-center py-1">
                <span class="me-2">¿Desea generar una factura electrónica? (Sí/No)</span>
                <v-switch class="d-inline-flex font-weight-medium black--text text--black pa-3 py-0"
                  :title="$t('instructions_pos_invoice_electronic')" prepend-icon="mdi-file-sign"
                  v-model="activeInvoiceElectronic" dense :label="$t('pos_invoice_electronic')"
                  @change="changeInvoiceElectronic()" v-if="$store.getters.getGeneralValue(
                    'gen_billing_invoice_electronically'
                  ) == true
                    "></v-switch></v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>

      </v-col>
    </v-row>

    <!--v-row v-if="order" class="pa-0 ma-0 grey lighten-4" ref="optionsOrder" id="options_order">
      <v-col class="pa-0 ma-0">
       
      </v-col>
    </v-row-->

    <v-divider></v-divider>
    <!-- :key="$store.getters.orderChanged" -->
    <div v-if="order" :style="getHeight()" class="white pa-0 ma-0">
      <v-list  shaped class="grey lighten-5">
        <v-list-item v-for="item in order.data_items" :key="item.id" class="cart-item">
          <CartSectionItem :item="item" @delete:item="deleteItem" @edit:item="$emit('edit:item', $event)">
          </CartSectionItem>
          <v-divider></v-divider>
        </v-list-item>
        <!--{{order.data_items}}-->
      </v-list>
    </div>

    <v-row v-if="order" class="pa-0 ma-0" ref="totalsSection" id="totals_section">
      <v-col class="pa-0 ma-0">
        <v-simple-table class="font-weight-black">
          <tbody>
            <tr class="">
              <td>
                <span class="font-weight-regular">{{ $t("taxes") }}:</span>
                {{
                  $filters.currency($pos.getCurrentOrder().getTotalTaxes(), 0)
                }}
              </td>
              <td class="font-weight-regular" v-if="$pos.getCurrentOrder().getPriceTip() > 0 ||
                  $pos.getCurrentOrder().getPriceDomicile() > 0
                  ">
                {{ $t("subtotal") }}:
              </td>
              <td v-else>
                <span class="font-weight-regular">{{ $t("total") }}:</span>
                :
              </td>
              <td>
                {{
                  $filters.currency($pos.getCurrentOrder().getTotalToPay(), 0)
                }}
              </td>
            </tr>

            <tr v-if="$pos.getCurrentOrder().getPriceTip() > 0 ||
                $pos.getCurrentOrder().getPriceDomicile() > 0
                ">
              <td :key="'tip_'+$store.getters.orderChanged" class="font-weight-regular">
                <div v-if="$pos.getCurrentOrder().getPriceTip() > 0">
                  {{ $t("tip") }}:
                  <v-chip color="green lighten-4" class="font-weight-regular black--text green--text text--darken-1">
                    {{
                      $filters.currency($pos.getCurrentOrder().getPriceTip(), 0)
                    }}
                    <v-icon small @click="modalTip = true" right>mdi-pencil</v-icon>
                  </v-chip>
                </div>
              </td>
              <td class="font-weight-regular">{{ $t("total") }}:</td>
              <td>
                {{
                  $filters.currency(
                    $pos.getCurrentOrder().getPriceTotalWithAdds(),
                    0
                  )
                }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row class="pa-0 ma-0" id="cart_options">
    

      <v-col class="pa-0 ma-0">
        <v-btn class="white--text" depressed tile block x-large color="primary" @click="saveOrderSeller()"
          :disabled="$pos.getCurrentOrder().isEmpty()">
          <v-icon left>mdi-content-save</v-icon>
          {{ $t("save") }}</v-btn>
      </v-col>
    </v-row>
    <CartCustomer :dialog="modalCustomer" @cancel="modalCustomer = false" @selected="selectedCustomer">
    </CartCustomer>
    <CartPayments :dialog="modalPayments" @cancel="cancelPayment" :order="$pos.getCurrentOrder()" :is_mobile="is_mobile">
    </CartPayments>
    <CartAnnulOrder :dialog="anullCurrentOrder" :order="$pos.getCurrentOrder()" :message="$t('really_cancel_order')"
      @close="anullCurrentOrder = false" @annul="annulOrder($event)"></CartAnnulOrder>
    <CartNote :dialog="modalNote" :order="$pos.getCurrentOrder()" @cancel="modalNote = false" @close="modalNote = false">
    </CartNote>
    <CartCoupon :dialog="modalCoupon" :order="$pos.getCurrentOrder()" @cancel="modalCoupon = false"
      @close="modalCoupon = false"></CartCoupon>
    <CartTip :dialog="modalTip" :order="$pos.getCurrentOrder()" @cancel="modalTip = false"
      @modalSetAutomaticTip="modalSetAutomaticTip()" @close="modalTip = false"></CartTip>

      <div >
        <CartTablesRemote :dialog="modalTables" :order="$pos.getCurrentOrder()" @cancel="modalTables = false"
      @close="modalTables = false"></CartTablesRemote>
      </div>
   

    <ModalTryAgain ref="modalTryAgain" @try-save-order="saveOrderSeller()" />
  </v-col>
</template>




<script>
import CartSectionItem from "./../pos/components/CartSectionItem";
import CartCustomer from "./../pos/components/CartCustomer";
import CartPayments from "./../pos/components/CartPayments";
import CartAnnulOrder from "./../pos/components/CartAnnulOrder";
import CartNote from "./../pos/components/CartNote";
import CartTables from "./../pos/components/CartTables";
import CartCoupon from "./../pos/components/CartCoupon";
import CartTip from "./../pos/components/CartTip";
import CartTablesRemote from "./../pos/components/CartTablesRemote";
import { isStoreActive } from "@/plugins/db-firestore";
import ModalTryAgain from "./../pos/components/ModalTryAgain.vue";
export default {
  name: "CartSection",
  components: {
    CartSectionItem,
    CartCustomer,
    CartPayments,
    CartAnnulOrder,
    CartNote,
    CartTables,
    CartTablesRemote,
    CartCoupon,
    CartTip,
    ModalTryAgain,
  },
  props: ["is_mobile", "aspect_ratio", "modeSeller"],
  data: () => ({
    tab: null,
    vHeight: 67,
    order: null,
    modalCoupon: false,
    modalCustomer: false,
    modalPayments: false,
    modalNote: false,
    modalTip: false,
    modalTables: false,
    anullCurrentOrder: false,
    employees: [],
    sellers: [],
    domiciliaries: [],
    domiciliary: null,
    seller: null,
    price_delivery: null,
    activeTip: false,
    activeInvoiceElectronic: false,
    orderType: "is_on_table",
  }),
  watch: {
    "$store.getters.orderChanged": function () {
      //this.order = this.$pos.getCurrentOrder();
      //console.log("order changed .................................");
      this.$set(this, "order", this.$pos.getCurrentOrder());
      this.setDefaultsOnChargeOrder();
    },
  },
  //props: ['order'],
  mounted() {
    this.order = this.$pos.getCurrentOrder();
    setTimeout(() => {
      this.setDefaultsOnChargeOrder();
      this.getEmployees();
  

      /* */
    }, 200);
  },
  methods: {
    getPendingOrder(id) {
      this.$http.get("api/v1/orders-pending", { id: id }).then((response) => {
        //this.orders = response.items;
        this.$db.updateOrders(response.items);
      });
    },
    allowedPayment() {
      let order = this.$pos.getCurrentOrder();
      if (order.is_electronic_invoice) {
        if (!order.data_customer) {
          return false;
        }
      }
      return true;
    },
    activateCoupons() {
      return true;
    },
    activateDomicilies() {
      return false;
    },
    async getValueActivePrintRemote(){
      let value = false;
      let installation = this.$store.getters.installation;		
      if (installation) {
        const cashRegister = await this.$db.getCashRegister(
          installation.cash_id
        );
        if(cashRegister){
          if(cashRegister.config){
            if(cashRegister.config.pos_active_print_remote){
              value = cashRegister.config.pos_active_print_remote;
            }
          }
        }
      }
      return value;
    },
    pay() {
      if (this.order.is_domicile) {
        if (
          !this.order.data_customer ||
          !this.order.data_customer["first_name"] ||
          !this.order.data_customer["phone"] ||
          !this.order.data_customer["address"]
        ) {
          this.modalCustomer = true;
          return;
        }
      }
      this.modalPayments = true;
    },
    annulOrder(order) {
      this.anullCurrentOrder = false;
      this.createNewOrder();
    },
    haveCoupon() {
      if (this.order.getCoupon()) {
        return true;
      }
      return false;
    },
    cancelPayment(params) {
      if (params) {
        if (params.orderfinalized) {
          this.createNewOrder();
          this.activeTip = false;
          this.activeInvoiceElectronic = false;
        }
      }
      this.modalPayments = false;
    },
    openAnnulOrder() {
      this.anullCurrentOrder = true;
    },
    changeOrderType(type) {
      //ver orden en que estan los botones en el v-btn-toggle
      if (this.orderType == "is_direct") {
        //en_tienda
        this.setVHeight();
        this.$pos.setIsDomicile(this.order, false);
        this.$pos.setIsOnTable(this.order, false);
        this.$pos.setNameTable(this.order, null);
        this.$pos.setStorePickup(this.order, true);
        this.$pos.setPriceDomicile(this.order, 0);
        this.$pos.setDomiciliary(null);
      }
      if (this.orderType == "is_on_table") {
        this.setVHeight();
        this.modalTables = true;
        this.$pos.setIsOnTable(this.order, true);
        this.$pos.setIsDomicile(this.order, false);
        this.$pos.setStorePickup(this.order, false);
        this.$pos.setPriceDomicile(this.order, 0);
        this.$pos.setDomiciliary(null);
      }
      if (this.orderType == "is_domicile") {
        //domicilios
        this.setVHeight();
        this.$pos.setIsDomicile(this.order, true);
        this.$pos.setIsOnTable(this.order, false);
        this.$pos.setNameTable(this.order, null);
        this.$pos.setStorePickup(this.order, false);
      }
    },
    electronicInvoiceHint() {
      return this.$t("invoice_electronic");
    },
    async saveOrderSeller() {
      // naren
      // validar con isStoreActive si la tienda esta activa
      // si no esta activa le salga una modal indicando que el pos no esta recibiendo las ordenes
      // y un boton de actualizar
      let installation = this.$store.getters.installation;
      let active = await isStoreActive(installation.subsidiary_id, 1);
      if (!active) {
        this.$refs.modalTryAgain.renderItem();
        return;
      }
      this.$pos.setOrderStatus(this.$pos.getCurrentOrder(), "pending");
      let name_table = this.$pos.getCurrentOrder().getNameTable();
      console.log('table: '+name_table)
     if (name_table) {
        //let table = await this.$db.getTableByName(name_table);
        //await this.$db.updateTableBusy(table, true);
      }else{
        this.modalTables = true;
        //alert('Debe seleccionar una mesa')
        return
      }
      
      // naren en el api de guardar la orden se debe validar si from_seller es true, en este caso
      // se debe notificar por firebase desde laravel el evento: id de la orden el time, y st=draft
      // y tambien enviar una notificacion push al mesero
      let response = await this.$pos.saveOrderSeller(
        this.$pos.getCurrentOrder(),
        this.$http
      );
      //firestore notification
 
      this.createNewOrder();

    },
    createNewOrder() {
      let defaults = {is_on_table: true, from_seller: true};
      this.$pos.createOrder(false, defaults);
      this.$store.dispatch("createdOrder");
    },
    async save() {
      this.$pos.setOrderStatus(this.$pos.getCurrentOrder(), "received");
      /*if (this.$pos.getCurrentOrder().getIsOnTable()) {
        let name_table = this.$pos.getCurrentOrder().getNameTable();
        if (name_table) {
          let table = await this.$db.getTableByName(name_table);
          await this.$db.updateTableBusy(table, true);
        }
      }*/
      this.$pos.saveOrder(this.$pos.getCurrentOrder(), this.$http);
      
      this.createNewOrder();
    },
    selectedCustomer(customer) {
      this.$pos.setCustomer(customer);
      this.modalCustomer = false;
    },
    deleteItem(itemLine) {
      //si la orden ya se imprimio debe escribir un motivo, se guarda el motivo en la orden
      this.$pos.deleteItem(itemLine);
      this.$pos.setPriceTip(this.$pos.getCurrentOrder(), null);
    },
    removeClient() {
      this.$pos.setCustomer(null);
      this.modalCustomer = false;
    },
    noteOrder() {
      this.modalNote = true;
    },
    openModalCoupon() {
      this.modalCoupon = true;
    },
    openModalTables() {
      this.modalTables = true;
    },
    openModalTip() {
      this.modalTip = true;
    },
    changeSeller(evt) {
      this.$pos.setSeller(evt);
    },
    changeDomiciliary(evt) {
      this.$pos.setDomiciliary(evt);
    },
    changePriceDelivery(evt) {
      if (evt) {
        this.$pos.setPriceDomicile(this.order, parseInt(evt));
      } else {
        this.$pos.setPriceDomicile(this.order, 0);
      }
    },
    changeInvoiceElectronic() {
      if (this.activeInvoiceElectronic) {
        let order = this.$pos.getCurrentOrder();
        if (!order.data_customer) {
          this.modalCustomer = true;
        }
      }

      this.$pos.setIsElectronicInvoice(
        this.order,
        this.activeInvoiceElectronic
      );
    },
    changeTip() {
      this.$pos.setActiveTip(this.$pos.getCurrentOrder(), this.activeTip);
      if (this.activeTip) {
        this.$pos.setPriceTip(this.order, null);
      } else {
        this.$pos.setPriceTip(this.order, 0);
      }
      this.setVHeight();
    },
    printRemote() {
      if (this.order.getTotalItemsPrintRemote() > 0) {
        this.$pos.printRemote(this.order, false);
      } else {
        this.$pos.printRemote(this.order, true);
      }
    },
    printScreen() {
      this.$pos.printReceiptScreen(this.order);
    },
    setColorNote() {
      if (this.order.getNote()) {
        return "primary";
      }
    },
    setDefaultsOnChargeOrder() {
      if (this.order.getIsOnTable()) {
        this.orderType = "is_on_table";
      } else if (this.order.getIsDomicile()) {
        this.orderType = "is_domicile";
      } else {
        this.orderType = "is_direct";
      }

      if (this.order.getPriceTip() > 0) {
        this.activeTip = true;
      }

      if (this.order.getIsElectronicInvoice()) {
        this.activeInvoiceElectronic = true;
      }

      if (this.order.getBusPersonDomiciliaryId()) {
        this.domiciliary = this.order.getDataDomiciliary();
      } else {
        this.domiciliary = null;
      }

      if (this.order.getBusPersonSellerId()) {
        this.seller = this.order.getDataSeller();
      } else {
        this.seller = null;
      }

      if (this.order.getPriceDomicile() && this.order.getPriceDomicile() > 0) {
        this.price_delivery = this.order.getPriceDomicile();
      }
      this.setVHeight();
    },
    getHeight() {
      let th = 58;
      if (this.is_mobile) {
        // return "";
        // th = 15;
      }
      if (this.$refs.optionsOrder) {
  
        th += this.$refs.optionsOrder.clientHeight;
        th += this.$refs.totalsSection.clientHeight;
        return "overflow-y:auto;height: calc(100vh - " + th + "px);";
      } else {
        return "overflow-y:auto;height: 25vh;";
      }
    },
    setVHeight() {
   
    },
    async getEmployees() {
      const sellers = await this.$db.getEmployeesByType("seller");
      this.sellers = sellers;
      const domiciliaries = await this.$db.getEmployeesByType("deliverer");
      this.domiciliaries = domiciliaries;
    },
    modalSetAutomaticTip($event) {
    
    },
    setColspanMobile(value) {
      if (this.is_mobile) {
        return value;
      }
      return 1;
    },
    setColAspect() {
      const aspect = ["5:4", "4:3", "16:9", "16:10"];
      if (this.aspect_ratio) {
        if (this.aspect_ratio.aspect_ratio) {
          if (this.inArray(this.aspect_ratio.aspect_ratio, aspect)) {
            return 5;
          }
        }
      }
      return 4;
    },
    inArray(needle, haystack) {
      var length = haystack.length;
      for (var i = 0; i < length; i++) {
        if (haystack[i] == needle) return true;
      }
      return false;
    },
  },
};
</script>
<style scoped>
.btn-pay {
  background: #56ab2f;
  background: -webkit-linear-gradient(to right, #a8e063, #56ab2f);
  background: linear-gradient(to right, #a8e063, #56ab2f);
}

.btn-save {
  background: #00B4DB;
  background: -webkit-linear-gradient(to left, #0083B0, #00B4DB);
  background: linear-gradient(to left, #0083B0, #00B4DB);
}

.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.theme--light.v-btn-toggle:not(.v-btn-toggle--group) {
  background: none;
}

.v-btn-toggle>.v-btn.v-btn {
  border-style: none;
}

.v-item--active {
  border: none !important;
  background: #e4e4e4 !important;
  color: #24a9e1;
  font-size: 0.875rem;
}

.v-item--active .v-icon,
.v-item--active .v-btn__content span {
  /* color: #24A9E1 !important; */
  color: #24a9e1 !important;
}

.v-btn:before {
  background-color: #e4e4e4 !important;
}
</style>

<style>
@media only screen and (max-width: 981px) {
  #totals_section {
    bottom: 5ch;
  }

  #cart_options {
    bottom: 0;
    background: #FAFAFA;
  }

  #totals_section,
  #cart_options {
    position: fixed;
    z-index: 100;
    width: 100%;
  }
}


.desktop-height {
  height: calc(100vh - 70px) !important
}

.mobile-height {
  height: calc(100vh - 14ch) !important
}

.cart_options .v-select>.v-input__control>.v-input__slot label {
  color: rgba(0, 0, 0, 1);
  font-size: 0.875rem !important;
}

.cart_options .v-text-field>.v-input__control>.v-input__slot label {
  color: rgba(0, 0, 0, 1);
  font-size: 0.875rem !important;
}

.cart_options .v-input--switch>.v-input__control>.v-input__slot label {
  color: rgba(0, 0, 0, 1);
  font-size: 0.875rem !important;
  font-weight: 400 !important;
}

.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:not(.v-data-table__mobile-row),
.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>th:not(.v-data-table__mobile-row) {
  border-bottom: none;
}

.cart_coupon_fix span {
  top: -5px;
}
</style>